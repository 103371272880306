import React from 'react';
import { Outlet } from 'react-router-dom';

import { Breadcrumbs } from '@/components-new/breadcrumbs';
import { Page } from '@/components-new/page';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Tab, TabGroup, TabList, TabPanels } from '@/components-new/tab';
import { useCurrentUser } from '@/hooks/use-current-user';
import { useTabs } from '@/hooks/use-tabs';
import { insertIf } from '@/utils/arrays';

const CalendarIndexLayout = () => {
  const { hasPolicies } = useCurrentUser();
  const isClientUser = hasPolicies(['isClientUser']);

  const {
    tabs,
    activeTabIndex,
    onChange
  } = useTabs([
    {
      label: 'All Drugs',
      href: `/calendar${window.location.search}`
    },
    ...insertIf(isClientUser, {
      label: 'Tracked Drugs',
      href: `/calendar/tracked${window.location.search}`
    },
    {
      label: 'My Priority States',
      href: `/calendar/priority${window.location.search}`,
      transition: true
    })
  ]);

  return (
    <Page
      title="Calendar"
      header={({ title }) => (
        <>
          <Breadcrumbs
            breadcrumbs={[
              { label: 'Calendar', url: '/calendar' },
            ]}
          />
          <PageTitleRow title={title}/>
        </>
      )}
    >
      <TabGroup selectedIndex={activeTabIndex} onChange={onChange}>
        <TabList>
          {tabs.map((tab) => (
            <Tab key={tab.href}>
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          <div className="mt-2">
            <Outlet />
          </div>
        </TabPanels>
      </TabGroup>
    </Page>
  );
};

export default CalendarIndexLayout;
